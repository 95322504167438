.App {
  max-height: 100vh;
  min-height: 110vh;
  text-align: center;
  overflow-y: scroll;
  background-color: rgb(231,231,233);
}

.photo {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.photo img {
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 86%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  line-height: 0.2px;
  padding-top: 50px;
}

.countdown{  
  margin-top: -30px;
  height: 25vh;
}

.despedida {
  margin: 50px;
}

.info{ 
  margin-top: 80px;
}

.info-box {
  margin-top: 50px;
}

.ring-img {
  filter: blur(2px);
}

.asistencia {
  margin: 50px;
}
.calltoaction {
  width: 200px;
  height: 40px;
  background-color: #c2afa1;
  color: white;
  border-style: none;
  font-size: 15px; 
}

.down{
  position: relative;
  top:-30px;
  color: white;
  font-size: 25px;
  font-weight: lighter;
}

.count-box {
  display: flex;
  justify-content: space-around;
  margin: 30px;
  font-size: 20px;
}

.count-container, .cupos-container{
  background-color: #c2afa1;
  color: white;
  width: 50px;
  height: 50px; 
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.cupos-container {
  width: 70px;
  height: 70px; 
  background-color: #ae9c90;
  font-size: 30px;
  font-weight: bolder;
}


.date{
  font-size: 28px;
  font-family: 'Kaiven';
  margin-bottom: 40px;
}

.cursive{
  font-size: 28px;
  font-family: 'Kaiven', cursive;
  margin-bottom: 40px;
}

.icon {
  font-size: 55px;
}

h1 {
  font-size: 50px;
  font-weight: bold;
  font-family: 'Kaiven';
}

h2 {
  font-size: 37px; 
  font-family: 'Kaiven';
}

p {
  margin: 3%;
  font-size: 23px;
  font-family: 'Kaiven', serif;
  font-weight: lighter;
  color: rgb(90, 90, 90);
}



#countdown {
  font-size: 24px;
  text-align: center; 
}